
            @import 'src/styles/mixins.scss';
        
@import "@styles/sassVariables";

.ftr {
    background-color: #f8f8f8;
    border-top: 1px solid #c4c4c4;
    padding-bottom: 140px;
    @media screen and (min-width: 767px) {
        padding-bottom: 0;
    }
}
.ftrContainer {
    padding-left: 20px;
    padding-right: 20px;
}
.logo {
    svg {
        margin-left: auto;
        margin-right: auto;
        @include md {
            margin-left: 0;
            margin-right: 0;
        }
    }
}
.footerMenus {
    font-size: 12px;

    @include md {
        font-size: 18px;
    }
}
.ftrLnk {
    color: #858585;
    cursor: pointer;
    font-weight: 400;
    font-size: 12px;
}
.copyright {
    color: #000;
    font-weight: 400;
    font-size: 12px;
}
